.first-form form {
	padding-top: 3vh;
}

.first-form form .form-body .form-el input {
	padding-bottom: 10px;
}

.first-form.sign-up-form form .form-body .form-el {
	margin: 0 10px 20px;
}

.form-el .ui-selectonemenu {
	width: 100% !important;
	background: inherit !important;
	border: inherit !important;
}

.form-el .ui-selectonemenu-label {
	background: none;
	text-indent: 15px;
	color: #3b3b3b;
	opacity: .6;
	font-size: 18px;
	text-indent: 15px;
}

.form-el .ui-selectonemenu-trigger {
	background: none;
	border: none !important;
}